import React from 'react';
import WheelOfFortune from "./pages/wheelOfFortune";

function App() {
  return (
      <WheelOfFortune />

  );
}

export default App;
