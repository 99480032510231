import React, { useState, useRef, useEffect } from 'react';
import './wheelOfFortune.css';
import driver from '../assets/img/driver.webp';
import iron5 from '../assets/img/iron5.png';
import iron6 from '../assets/img/iron6.png';
import iron7 from '../assets/img/iron7.webp';
import iron8 from '../assets/img/iron8.png';
import iron9 from '../assets/img/iron9.png';
import lwedge from '../assets/img/lwedge.png';
import swedge from '../assets/img/swedge.webp';
import pwedge from '../assets/img/pwedge.png';
import putter from '../assets/img/putter.webp';
import joker from '../assets/img/joker.png';
import wood3 from '../assets/img/wood3.webp';
import hybrid from '../assets/img/hybrid.png';
import spinningSound from '../assets/img/spinning.wav';
import finishSound from '../assets/img/finish.mp3';
import clickSound from '../assets/img/click.mp3';
import refresh from '../assets/img/refresh.svg';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';  // Importiere das Settings-Icon
import TuneIcon from '@mui/icons-material/Tune';

function WheelOfFortune() {
  const initialOptions = [
    { img: driver, text: 'Driver', info: '190 to 250 meters | 8° to 12° Loft', id: 1 },
    { img: wood3, text: 'Wood', info: '150 to 230 meters | 15° to 19° Loft', id: 2 },
    { img: hybrid, text: 'Hybrid', info: '130 to 210 meters | 18° to 24° Loft', id: 3 },
    { img: iron5, text: '5 Iron', info: '130 to 190 meters | 27° Loft', id: 4 },
    { img: iron6, text: '6 Iron', info: '120 to 170 meters | 31° Loft', id: 5 },
    { img: iron7, text: '7 Iron', info: '110 to 160 meters | 35° Loft', id: 6 },
    { img: iron8, text: '8 Iron', info: '100 to 140 meters | 39° Loft', id: 7 },
    { img: iron9, text: '9 Iron', info: '90 to 130 meters | 44° Loft', id: 8 },
    { img: pwedge, text: 'PWedge', info: '80 to 120 meters | 48° Loft', id: 9 },
    { img: swedge, text: 'SWedge', info: '50 to 90 meters | 56° Loft', id: 10 },
    { img: lwedge, text: 'LWedge', info: '30 to 70 meters | 60° Loft', id: 11 },
    { img: putter, text: 'Putter', info: 'Green | 4° Loft', id: 12 },
    { img: joker, text: 'Joker', info: '∞', id: 13 },
  ];

  const [selectedOptions, setSelectedOptions] = useState(initialOptions.map(option => option.id));
  const [repeatedOptions, setRepeatedOptions] = useState([]);
  const [running, setRunning] = useState(false);
  const [selectedOption, setSelectedOption] = useState({ img: driver, text: 'Club', info: 'Length', id: 0 });
  const optionsRef = useRef(null);
  const containerRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const audioRef = useRef(null);
  const finishAudioRef = useRef(null);
  const startAudioRef = useRef(null);

  const [players, setPlayers] = useState([{ id: 1, name: 'Player 1', score: 0, isEditing: false }]);
  const [nextPlayerId, setNextPlayerId] = useState(2);
  const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);

  useEffect(() => {
    const filteredOptions = initialOptions.filter(option => selectedOptions.includes(option.id));
    const jokerOption = filteredOptions.find(option => option.id === 13);
    const nonJokerOptions = filteredOptions.filter(option => option.id !== 13);

    let tempOptions = [];
    for (let i = 0; i < 20; i++) {
      tempOptions.push(...nonJokerOptions);
      if (i % 4 === 0 && jokerOption) {
        tempOptions.push(jokerOption);
      }
    }

    setRepeatedOptions(tempOptions);
  }, [selectedOptions]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = 1;
    }
    if (finishAudioRef.current) {
      finishAudioRef.current.volume = 0.3;
    }
  }, []);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handleCheckboxChange = (id) => {
    setSelectedOptions(prevSelectedOptions =>
      prevSelectedOptions.includes(id)
        ? prevSelectedOptions.filter(optionId => optionId !== id)
        : [...prevSelectedOptions, id]
    );
  };

  const startWheel = () => {
    setRunning(true);

    const shuffledOptions = shuffleArray([...repeatedOptions]);
    setRepeatedOptions(shuffledOptions);

    const optionsContainer = optionsRef.current;
    const randomDuration = 5000 + Math.floor(Math.random() * 500);
    const randomIndex = Math.floor(Math.random() * shuffledOptions.length);
    const itemWidth = optionsContainer.scrollWidth / shuffledOptions.length;
    const containerWidth = containerRef.current.clientWidth;
    const randomOffset = (Math.random() - 0.5) * itemWidth;
    const endScrollPosition = randomIndex * itemWidth - (containerWidth / 2 - itemWidth / 2) + randomOffset;

    const animation = optionsContainer.animate([
      { transform: `translateX(0px)` },
      { transform: `translateX(-${endScrollPosition}px)` }
    ], {
      duration: randomDuration,
      easing: 'cubic-bezier(0, 1, 0.8, 1)'
    });

    animation.onfinish = () => {
      optionsContainer.style.transform = `translateX(-${endScrollPosition}px)`;
      setRunning(false);
      setSelectedOption(shuffledOptions[randomIndex]);

      setCurrentPlayerIndex((prevIndex) => (prevIndex + 1) % players.length);
    };

    if (audioRef.current) {
      audioRef.current.playbackRate = 1;
      audioRef.current.play();
    }

    if (startAudioRef.current) {
      startAudioRef.current.play();
    }

    if (finishAudioRef.current) {
      setTimeout(() => {
        finishAudioRef.current.play();
      }, randomDuration - 400);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const addPlayer = () => {
    if (players.length < 4) {
      setPlayers([...players, { id: nextPlayerId, name: `Player ${nextPlayerId}`, score: 0, isEditing: false }]);
      setNextPlayerId(nextPlayerId + 1);
    }
  };

  const updateScore = (id, delta) => {
    setPlayers(players.map(player =>
      player.id === id ? { ...player, score: player.score + delta } : player
    ));
  };

  const deletePlayer = (id) => {
    if (players.length > 1) {
      setPlayers(players.filter(player => player.id !== id));
    }
  };

  const toggleEditing = (id) => {
    setPlayers(players.map(player =>
      player.id === id ? { ...player, isEditing: !player.isEditing } : player
    ));
  };

  const updatePlayerName = (id, newName) => {
    setPlayers(players.map(player =>
      player.id === id ? { ...player, name: newName } : player
    ));
  };

  const resetScores = () => {
    setPlayers(players.map(player => ({ ...player, score: 0 })));
  };

  return (
    <>
      <div className='settingsIcon' onClick={toggleModal}>
        <TuneIcon style={{ fontSize: 20, cursor: 'pointer' }} />
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={toggleModal}>&times;</span>
            <span className='option1'>Choose your Clubs</span>
            <div className='line'></div>
            <div className="controls">
              {initialOptions.map(option => (
                <div key={option.id} className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedOptions.includes(option.id)}
                      onChange={() => handleCheckboxChange(option.id)}
                    />
                    <span className="checkmark"></span>
                    {option.text}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className='headline'>CLUB OF FORTUNE<div className='line'></div></div>

      <div className="wheelOfFortune">
        <div className="needle"></div>
        <div className="options-container" ref={containerRef}>
          <div className="options" ref={optionsRef}>
            {repeatedOptions.map((option, index) => (
              <div key={index} className="option">
                {option.img && <img src={option.img} alt={option.text} />}
                <div>{option.text}</div>
              </div>
            ))}
          </div>
        </div>

        {selectedOption && (
          <div className="selected-option">
            <span className='option1'>{selectedOption.text}</span> <span className='option2'>{selectedOption.info}</span>
          </div>
        )}
        <button className='spinButton' onClick={startWheel} disabled={running || selectedOptions.length === 0}>
          <img src={refresh} alt="Spin"/>
        </button>
      </div>

      <div className="players">
      <div className="selected-option">
        <span className='option1' >{players[currentPlayerIndex].name}</span> <span className='option3'> is next </span>
        </div>

        {players.map((player, index) => (
          <div key={player.id} className={`player ${index === currentPlayerIndex ? 'current-player' : ''}`}>
            {player.isEditing ? (
              <input 
                type="text"
                value={player.name}
                onChange={(e) => updatePlayerName(player.id, e.target.value)}
                onBlur={() => toggleEditing(player.id)}
              />
            ) : (
              <span  style={{textAlign: 'left', width: '110px', fontWeight:700}} onClick={() => toggleEditing(player.id)}>{player.name}</span>
            )}
                    <div style={{width: '110px'}}>
            <button onClick={() => updateScore(player.id, -1)}>-</button>
            <span>{player.score}</span>
            <button onClick={() => updateScore(player.id, 1)}>+</button>
            </div>
            <div style={{width: '70px'}}>
            <EditIcon onClick={() => toggleEditing(player.id)} style={{ cursor: 'pointer', marginLeft: '5px' }} />
            <DeleteIcon onClick={() => deletePlayer(player.id)} style={{ cursor: 'pointer', marginLeft: '5px' }} />
          </div>
          </div>
        ))}
               
        <button 
          style={{marginTop: '10px'}} 
          onClick={addPlayer} 
          disabled={players.length >= 4}
        >
          Add Player
        </button>
              
        <br></br>
        <button onClick={resetScores} className='resetButton'>Reset</button>
      </div>

      <audio ref={audioRef} src={spinningSound}></audio>
      <audio ref={finishAudioRef} src={finishSound}></audio>
      <audio ref={startAudioRef} src={clickSound}></audio>
    </>
  );
}

export default WheelOfFortune;